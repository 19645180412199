<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="email"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :error-messages="errorMessages.password"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-2"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                hide-details
                label="Remember Me"
                class="mt-0"
              >
              </v-checkbox>

              <!-- forget link -->
              <router-link
                :to="{name:'auth-forgot-password'}"
                class="ms-3"
              >
                Forgot Password?
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              type="submit"
              class="mt-6"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <p class="mb-0 me-2">
            New on our platform?
          </p>
          <router-link :to="{name:'auth-register'}">
            Create an account
          </router-link>
        </v-card-text>

      </v-card>

    </div>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >


  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import themeConfig from '@themeConfig'
import axios from '@axios'
import { useRouter } from '@core/utils'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {

      errorMessages.value = [];

      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      axios
        .post(themeConfig.api_prefix + '/auth/login', { email: email.value, password: password.value })
        .then(response => {
          const { accessToken } = { accessToken: response.data.token }

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          const { user } = response.data
          const { ability: userAbility } = user

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // We will store `userAbility` in localStorage separate from userData
          // Hence, we are just removing it from user object
          delete user.ability

          // Set user's data in localStorage for UI/Other purpose
          localStorage.setItem('userData', JSON.stringify(user))

          if (user.is_superuser == 1) {
              router.push({name:'apps-invoice-list'})
          } else {
              router.push({name:'apps-calendar'})
          }

        })
        .catch(error => {
          errorMessages.value = error.response.data.error
        })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
